@use "./themes" as themes;
@use '@angular/material' as mat;
@use 'sass:map';
@use 'sass:meta';

@function get-theme($primary-pallete, $accent-pallete, $warn-pallete, $additional-properties: null, $is-dark: false) {
  $theme: null;
  $theme-config: (
    color: (
      primary: $primary-pallete,
      accent: $accent-pallete,
      warn: $warn-pallete
    )
  );

  @if ($is-dark) {
    $theme: mat.m2-define-light-theme($theme-config);
  } @else {
    $theme: mat.m2-define-light-theme($theme-config);
  }

  $additional-properties: (additional-properties: if($additional-properties, $additional-properties, ()));
  $theme: map.merge($theme, (color: map.merge(map-get($theme, color), $additional-properties)));

  @return $theme;
}

@mixin init-theme($theme-config, $is-dark: false, $is-default: false) {
  $theme-color-config: map.get($theme-config, color);

  $primary-palette: mat.m2-define-palette(map.get($theme-color-config, primary));
  $accent-palette: mat.m2-define-palette(map.get($theme-color-config, accent));
  $additional-properties: map.get($theme-color-config, additional-properties);
  $warn-palette: ();

  @if(map.has-key($theme-color-config, warn)) {
    $warn-palette: mat.m2-define-palette(map.get($theme-color-config, warn));
  } @else {
    $warn-palette: null;
  }

  $theme: get-theme($primary-palette, $accent-palette, $warn-palette, $additional-properties, $is-dark);
  @include mat.all-component-themes($theme);
  @include mat.checkbox-density(-1);
}

@mixin mat-button-text-color($isDark: true) {
  $text-color: #000000;
  @if isDark {
    $text-color: #ffffff;
  }

  .mat-primary, .mat-accent, .mat-warn {
    &.mat-mdc-button-base {
      --mat-fab-foreground-color: #{$text-color};
      --mdc-filled-button-label-text-color: #{$text-color};
      --mdc-protected-button-label-text-color: #{$text-color};
    }

    &.mat-mdc-fab,
    &.mat-mdc-mini-fab {
      --mat-icon-color: #{$text-color};
    }
  }
}

/// Mixin Contextify
/// @access private
/// @param {String} $context - class name which will create context
@mixin contextify($context) {
  // in components
  :host-context(.#{$context}) & {
    @content;
  }

  // global styles
  .#{$context} & {
    @content;
  }
}

/// Function get-default-value-for-color-config-key
/// @access private
/// @param {String} $key - key of theme color config, one of primary, accent or warn
@function get-default-value-for-color-config-key($key) {
  $default-theme-config: map.get(themes.$themes, default);
  $default-theme-color-config: map.get($default-theme-config, color);

  @return map.get($default-theme-color-config, $key);
}

@function get-theme-color-rgb($color-group, $color-group-key) {
  $color: var(--theme-#{$color-group}-#{$color-group-key}-rgb);
  @return $color;
}

@function get-theme-color(
  $hue: 500,
  $opacity: null,
  $palette: "primary",
  $is-contrast-color: false
) {
  @if ($is-contrast-color) {
    @if ($opacity != null) {
      @return rgba(var(--theme-#{$palette}-contrast-#{$hue}-rgb), $opacity);
    } @else {
      @return var(--theme-#{$palette}-contrast-#{$hue});
    }
  } @else {
    @if ($opacity != null) {
      @return rgba(var(--theme-#{$palette}-#{$hue}-rgb), $opacity);
    } @else {
      @return var(--theme-#{$palette}-#{$hue});
    }
  }
}

/// Mixin Themify
/// @access private
/// @param {String} $property - themifiying property (color, background-color, border-color etc.)
/// @param {String} $color-group - group of theme colors (`primary`, `secondary`, `warn`)
/// @param {Number} $color-group-value - numeric value of the color group
@mixin themify($property, $color-group, $color-group-key, $alpha: null) {
  // Loop through themes
  @each $theme-name, $theme-obj in themes.$themes {

    // Create selecor ( Eg: `.theme-default .some-component-or-element-class`)
    @include contextify(theme-#{$theme-name}) {
      #{$property}: get-theme-color($color-group-key, $alpha, $color-group, false);
    }
  }
}

@mixin theme-background-color($theme-color-group, $color-group-key, $alpha: 1) {
  @include themify('background-color', $theme-color-group, $color-group-key, $alpha);
}

@mixin theme-border-bottom-color($theme-color-group, $color-group-key, $alpha: 1) {
  @include themify('border-bottom-color', $theme-color-group, $color-group-key, $alpha);
}

@mixin theme-border-color($theme-color-group, $color-group-key, $alpha: 1) {
  @include themify('border-color', $theme-color-group, $color-group-key, $alpha);
}

@mixin theme-border-left-color($theme-color-group, $color-group-key, $alpha: 1) {
  @include themify('border-left-color', $theme-color-group, $color-group-key, $alpha);
}

@mixin theme-border-right-color($theme-color-group, $color-group-key, $alpha: 1) {
  @include themify('border-right-color', $theme-color-group, $color-group-key, $alpha);
}

@mixin theme-border-top-color($theme-color-group, $color-group-key, $alpha: 1) {
  @include themify('border-top-color', $theme-color-group, $color-group-key, $alpha);
}

@mixin theme-caret-color($theme-color-group, $color-group-key, $alpha: 1) {
  @include themify('caret-color', $theme-color-group, $color-group-key, $alpha);
}

@mixin theme-color($theme-color-group, $color-group-key, $alpha: 1) {
  @include themify('color', $theme-color-group, $color-group-key, $alpha);
}

@mixin theme-outline-color($theme-color-group, $color-group-key, $alpha: 1) {
  @include themify('outline-color', $theme-color-group, $color-group-key, $alpha);
}

@mixin theme-column-rule-color($theme-color-group, $color-group-key, $alpha: 1) {
  @include themify('column-rule-color', $theme-color-group, $color-group-key, $alpha);
}

@mixin theme-text-decoration-color($theme-color-group, $color-group-key, $alpha: 1) {
  @include themify('text-decoration-color', $theme-color-group, $color-group-key, $alpha);
}

@mixin theme-box-shadow($box-shadow-sizes, $theme-color-group, $color-group-key, $alpha: 1, $themes: themes.$themes) {
  // Loop through themes
  @each $theme-name, $theme-obj in $themes {
    $color-groups: map.get($theme-obj, color);
    $color-group-values: map.get($color-groups, $theme-color-group); // associated array, one of `primary`, `secondary` or `warn`

    @if ($color-group-values == null) {
      $color-group-values: get-default-value-for-color-config-key($color-groups);
    }

    // Create selector ( Eg: `.theme-default .some-component-or-element-class`)
    @include contextify(theme-#{$theme-name}) {
      box-shadow: #{$box-shadow-sizes} get-theme-color($color-group-key, $alpha, $theme-color-group);
    }
  }
}

@mixin theme-mat-progress-height($height, $border-radius: 0) {
  .mdc-linear-progress {
    --mdc-linear-progress-active-indicator-height: #{$height};
    --mdc-linear-progress-track-height: #{$height};
    border-radius: $border-radius;
  }
  ::ng-deep .mdc-linear-progress__bar-inner {
    border-top-width: $height !important;
  }
}

@mixin theme-mat-progress-color($fill-color, $back-color: transparent) {
  ::ng-deep .mdc-linear-progress__bar-inner {
    @if (meta.type-of($fill-color) == 'color') {
      border-color: $fill-color !important;
    } @else {
      @include theme-border-color(primary, if(meta.type-of($fill-color) == 'number', $fill-color, 500));
    }
  }
  ::ng-deep .mdc-linear-progress__buffer-bar {
    background-color: $back-color;
    opacity: 0.3;
  }
}

