@use '../variables' as var;
@use 'sass:map';

[data-title]:hover:after {
    transition: all 0.1s ease 0.5s;
    display: block;
}
[data-title]:after {
    content: attr(data-title);
    color: white;
    background-color: map.get(var.$mat-grey, 800);
    border-radius: .2rem;
    font-size: .725rem;
    padding: .5rem;
    max-width: 20rem;
    min-width: 7.5rem;
    width: auto;
    height: auto;

    white-space: normal;

    position: absolute;
    left: 75%;
    top: 100%;
    z-index: 10000;
    display: none;
}
[data-title] {
    position: relative;
}
