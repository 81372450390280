@use '../variables' as var;
@use '../mixins' as mixins;
@use 'sass:map';
@use '../themes/theme-mixins' as themeMixins;

.dialog-panel{
  .mat-mdc-dialog-container{
    box-shadow: 0 19px 38px rgba(62, 69, 81, 0.3), 0 15px 12px rgba(62, 69, 81, 0.2);
    border-radius: 4px;
    padding: 20px 25px 25px;
  }
  .mat-mdc-form-field{
    display: block;
    width: 100%;
  }
  .mat-subheading-1{
    font-weight: 500;
    margin-bottom: map.get(var.$portal_spacers, 2);
  }
}

.mat-mdc-table {
  font-size: 14px !important;

  .mat-mdc-header-cell {
    font-size: 12px;
    font-weight: 500 !important;
    color: map.get(var.$mat-grey, 600) !important;
  }
  .mat-mdc-cell, .mat-mdc-header-cell, .mat-mdc-footer-cell {
    padding: 0 map.get(var.$portal_spacers, 1);
    font-weight: normal;
    white-space: nowrap;
    &.word-wrap{
      white-space: normal;
    }
  }
  td.mat-mdc-cell:first-of-type, td.mat-mdc-footer-cell:first-of-type, th.mat-mdc-header-cell:first-of-type{
    padding-left: map.get(var.$portal_spacers, 2);
  }
  td.mat-mdc-cell:last-of-type, td.mat-mdc-footer-cell:last-of-type, th.mat-mdc-header-cell:last-of-type{
    padding-right: map.get(var.$portal_spacers, 2);
  }
  .mdc-data-table__row:hover {
    background-color: transparent !important;
  }
  .table-btn{
    padding: 0;
    min-width: 36px;
  }
}

.filter-fields-wrap{
  .mat-mdc-form-field-infix{
    @include mixins.respond-to(maxextralarge){
      width: auto;
    }
  }
}

.mat-body-1{
  word-break: break-word;
}

.input-without-top-gap {
  .mat-mdc-form-field-infix {
    border-top-width: 0 !important;
  }
}
$mat-progress-back-color: map.get(var.$mat-grey, 500);

@include themeMixins.theme-mat-progress-color(null, $mat-progress-back-color);
.warning {
  @include themeMixins.theme-mat-progress-color(map.get(var.$mat-orange, 300), $mat-progress-back-color);
}
.danger {
  @include themeMixins.theme-mat-progress-color(map.get(var.$mat-orange, 900), $mat-progress-back-color);
}
.finish {
  @include themeMixins.theme-mat-progress-color(map.get(var.$mat-orange, 900), map.get(var.$mat-orange, 900));
}

.mat {
  &-figure {
    .mat-mdc-form-field {
      flex: 1;
      margin: 0;
    }
  }
  &-button-wrapper {
    padding: 0!important;
    vertical-align: top;
  }
  &-grid-tile {
    &:nth-child(even) {
      .mat-mdc-form-field {
        padding-left: map.get(var.$portal_spacers, 3);
      }
    }
    &:nth-child(odd) {
      .mat-mdc-form-field {
        padding-right: map.get(var.$portal_spacers, 3);
      }
    }
  }
}

.selection-radio {
  .mat-mdc-radio {
    &-button {
      .mdc-radio {
        display: none;
      }
      label {
        max-width: 100%;
        color: var.$color_1;
        font-size: map.get(var.$typography, small);
        padding: 0.2rem 0.5rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
        border-radius: 30px;
        &:hover {
          background-color: map.get(var.$mat-grey, 50);
        }
      }
    }
    &-checked {
      label {
        .#{var.$app-wrapper-class} & {
          @include themeMixins.theme-background-color(primary, 100);
          color: white;
        }
      }
    }
  }
}

.mat-grid-tile {
  overflow: visible!important;
  .mat {
    &-figure {
      justify-content: flex-start!important;
    }
  }
  .mat-mdc-checkbox.selection-checkbox {
    label {
      border-radius: 30px;
      padding: 0.2rem 0.5rem;
      color: var.$color_1;
      font-size: map.get(var.$typography, small);
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
      &:hover {
        background-color: map.get(var.$mat-grey, 50);
      }
    }
    .mdc-checkbox {
      display: none;
    }
  }

  .mat-mdc-checkbox-checked.selection-checkbox {
    label {
      .#{var.$app-wrapper-class} & {
        @include themeMixins.theme-background-color(primary, 100);
        color: white;
      }
    }
  }

}

.mat-tab {
  &-body-wrapper {
    padding-top: map.get(var.$portal_spacers, 3)
  }
  &-label-content {
    text-transform: uppercase;
  }
}

// TODO: Remove those !important. And never use them again!
.mat-expansion {
  &-panel {
    box-shadow: none!important;
    margin-bottom: map.get(var.$portal_spacers, 4)!important;
    &-header {
      padding: 0!important;
      margin-bottom: map.get(var.$portal_spacers, 4)!important;
      h3 {
        margin-bottom: 0!important;
      }
    }
    &-body {
      padding: 0!important;
    }
    &-content {
      font-size: map.get(var.$typography, normal);
    }
  }
  &-indicator {
    margin-right: map.get(var.$portal_spacers, 3);
  }
}

.mat-mdc-card.table-card {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}

.mdc-button {
  font-weight: 600;
  font-size: 14px;
}

.mdc-checkbox__ripple {
  display: none;
}

.mat-mdc-form-field-focus-overlay {
  background-color: transparent !important;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent !important;
}

.mdc-text-field--disabled.mdc-text-field--filled {
  background-color: transparent !important;
}

.cdk-overlay-pane:has(.mat-mdc-select-panel) { min-width: fit-content; }

// Form field size styles

.mat-mdc-form-field {
  font-size: 1rem;
  font-weight: 500;

  .mdc-text-field {
    padding: 0;
  }
}

.mat-mdc-form-field-error-wrapper {
  padding: 0 16px 0 0 !important;
  font-size: 14px;
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 20px !important;
}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 15px !important;
  padding-bottom: 2px !important;
  min-height: 40px;
}

.mat-mdc-form-field-icon-suffix {
  height: fit-content;

  .mdc-icon-button {
    width: 40px !important;
    height: 40px !important;
  }
}

mat-form-field {
  line-height: 1;
}

.mat-mdc-form-field-bottom-align {
  height: 20px;

  &::before {
    height: 14px !important;
  }
}
// END: Form field size styles
