@use '../themes/theme-mixins' as mixins;
@use '../variables' as var;
@use 'sass:map';

.t-primary {
  @include mixins.theme-color(primary, 500);
}

.t-secondary {
  @include mixins.theme-color(accent, 500);
}

.t-warning {
  color: map.get(var.$mat-red, 500)
}

.link {
  @include mixins.theme-color(primary, 500);
  cursor: pointer;
}
